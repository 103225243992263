import type { sfConfType } from "@/types/sfConfType";
import { toRaw } from "vue";

export default class ToHostFunction {
  dataToSend: any;
  config: sfConfType;

  constructor(sfConfig: sfConfType) {
    this.config = sfConfig;
  }

  addData(dataToSend: any) {
    this.dataToSend = toRaw(dataToSend);
  }

  send() {
    const hostSendFunction = (window as any).sfShowModal;
    hostSendFunction(this.dataToSend);
  }
}
