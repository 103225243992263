
import { PropType, defineComponent, onMounted, ref } from "vue";

interface OptionsType {
  id: string;
  title: string;
}

export default defineComponent({
  name: "InputSelect",
  props: {
    optionsData: {
      type: Array as PropType<OptionsType[]>,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    description: {
      type: String,
      default: "",
    },
  },
  emits: ["changedVal"],
  setup: function (props, ctx) {
    const open = ref(false);
    const selected = ref("");

    const selectOption = (option: OptionsType) => {
      selected.value = option.title;
      open.value = false;
      ctx.emit("changedVal", option.id);
    };

    onMounted(() => {
      selected.value = props.default;
    });

    return {
      open,
      selectOption,
      selected,
    };
  },
});
