
import { computed, defineComponent } from "vue";
import Slider from "@vueform/slider";

export default defineComponent({
  name: "ComboInputSlider",
  props: {
    inputValue: {
      type: Number,
      default: 0,
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    description: {
      type: String,
      default: "",
    },
  },
  emits: ["changedVal"],
  components: {
    Slider,
  },
  setup: function (props, ctx) {
    const localInputValue = computed({
      get: () => props.inputValue,
      set: (newValue) => {
        ctx.emit("changedVal", newValue);
      },
    });

    const sliderUpdate = (val: number) => {
      localInputValue.value = val;
    };

    return {
      localInputValue,
      sliderUpdate,
    };
  },
});
