import axios from "axios";

// const accessToken =
//   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MDAwNDc5ODcsImV4cCI6MjA2MDA0Nzk4Nywicm9sZXMiOlsiUk9MRV9BRFZJU0VSIiwiUk9MRV9BUElfTU9SVEdBR0UiLCJST0xFX0FQSV9NT05FWV9MT0FOIiwiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoibWZpbmFwaXdpZGdldEB0ZXN0LnBsIiwiaWQiOjExMTE4MDUxLCJmaXJtVXVpZCI6IjVlMTY3ODU4LTU3YzktNGE2NS1iOThjLTMzZjQ5MjM2NTBlMSJ9.P0V25ZzEkIefZKdnvXqp_ELUVtuK-Ogh3lIhX7nIQTlinL-UQHyMtY4soFSkYez5WhN5Y8ZrDOqka-CGszTUw__GdxUe-DDQpHym7-ajvWtLb6vNQQSZE8Xa0sAldhWoVNDieecKj6fzjFFbd872d8gtCpHefg_C6YJJWmL09PfjX9itgYii5MPVtJhk5kAUQYZYPYjM8WxP8OeuTRASOuwoXbIUpID0RfJeHV6eLuCtkDafp8-gidGM9dKNv79f2jbzU5vAnCyYCqaafZoZqIPBsM0pILZ7-5TThH8u7Yh-CQgtH0XvQg0W50qCGlVSKsOL5Z54zwNbrPv1edYAWSCt3d4Xf2qmssbhqzodR_EYV1MEZigPyzPbXc06BJzCeT1jdpucShvFwFQZYZL095vxaIF7Zm1fGlzxQIYioIoSyM5V45q_TERewNQbn_8ZqWc-GPhVet30dFHnU8ESosryGo_Ug65kLr92FOTfSllUyHrBrFm280IsbqcWcpuFgqlUMtlv-_w4ElmUC6evjqPDMXmkQRBHugHl-xHGuLkVWlUUfoDOHeDnl2vNPU-pXK0eSnx9Q8jzcamAATJZ-uswI7KS6IPExHBy2Zo2KP2TU1qZLYvpN7IuRylpTxOMIciMkn4UNGJEGlP1JtZ3b9kq2iF12CfzX3GcUgrhQFU";

//axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] = "application/json";
//axios.defaults.headers.post["Access-Control-Allow-Credentials"] = true;
// axios.defaults.headers.get.Authorization = `Bearer ${accessToken}`;

const accessSendToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MDc5MTgxNTEsImV4cCI6MTcwNzkyMTc1MSwicm9sZXMiOlsiUk9MRV9BRFZJU0VSIiwiUk9MRV9BUEkiLCJST0xFX0FQSV9MRUFEIiwiUk9MRV9VU0VSIl0sInVzZXJuYW1lIjoid2lkZ2V0R0B0ZXN0LnRlc3QiLCJpZCI6MTExMTc4MTAsImZpcm1VdWlkIjoiYzVhNjZiYTItNTYxNS00ZDUzLWI4M2YtZjUxZmY1M2Q1MjQyIn0.EDkfH6X29JaWWslL67DHyvJMo9vmuwh9udleJh0UGuPQDC9F5_QctmhC8RdfU8IViVDSQxG4KyQyXfIkoHRvk9WKK1NgiJOxyrhZ1fE2hSBgzVfGf353LPL4h5EUhgXj2Tcd7XzfSKxssXPLC9lCnpwWK178uwsAdpCtBQ8DOn3ct_09TdFMr3yaGV_SO3n1bg4gr3Zr8eQlFBxxrbQ2JMZ_JjuNitalpFahcBr78ow-xbFwzeSt0tDGVqHPSW7JWEj5e8DvPnmeujowb8ky2FS603RrIN6zIcVtZdVPuW4y7XbcmXTgCQL6WDgjK5DDTv0F2SP9VFjGdq5VhigilLcmqkwN7_YeBiVU3G9tRDIMomMui0IU5zTNLCSfgI3eGqq7-RZoYOl5ThIRwz0SxUTNHTKtDHhCtJGeUHx9ks6FRVkCg0w_makrAeUmNDyVmcXqYPPTvlyKDDEOkWPqyg-xIuPYa4voMf7dOYxHYsukT0dWR-0cJ036ieQqilYTIK2M5bOmxEbmvbr_7Zq0npp6Iz7bgATv_0lKxxzstkaqm7C51OC2oZo0m0UBp74AR0AFR_7hA1wMWp-I88VY4UhonmG7Uv6YHyExY2hgtDv_JkQKZ-GBTqZW0oGojPF_z7vHq3l6v25zbb5ly3bHLsPc7ql95q1ILlDtn1C520s";

axios.defaults.headers.post.Authorization = `Bearer ${accessSendToken}`;

switch (process.env.NODE_ENV) {
  case "production":
    axios.defaults.baseURL = "https://api.calculator.speedfin.pl/api/v2";
    break;
  default:
    axios.defaults.baseURL =
      "https://api.calculator.zaufanyposrednik.pl/api/v2";
}
//axios.defaults.baseURL = "https://api.calculator.speedfin.pl/api/v2";
//axios.defaults.baseURL = "https://api.calculator.zaufanyposrednik.pl/api/v2";

export default axios;
