import type { ProductItemType } from "@/types/product";

type queryObjType = {
  creditValue?: number;
  creditPeriod?: number;
  creditPurpose?: number;
  secureValue?: number;
  creditClientAge?: number;
  propertySort?: number;
  fixedInterestRate?: number;
  variableInterestRate?: number;
  periodicalInterestRate?: number;
  eco?: number;
  bestBankOffer?: number;
  indexWibor?: number;
  indexWiron?: number;
  clientInternal?: number;
  clientExternal?: number;
};

export function formToQuery(
  creditVal: number,
  periodVal: number,
  creditPurposeVal: string,
  secureVal: number,
  ageVal: number,
  propertySortVal: string
) {
  const queryObj: queryObjType = {};
  // pseudo DTO
  queryObj.creditValue = Number(creditVal);
  queryObj.creditPeriod = Number(periodVal) * 12;
  queryObj.creditPurpose = Number(creditPurposeVal);
  queryObj.secureValue = Number(secureVal);
  queryObj.creditClientAge = Number(ageVal);
  queryObj.propertySort = Number(propertySortVal);

  queryObj.fixedInterestRate = 1;
  queryObj.variableInterestRate = 1;
  queryObj.periodicalInterestRate = 0;
  queryObj.eco = 0;
  queryObj.bestBankOffer = 1;
  queryObj.indexWibor = 1;
  queryObj.indexWiron = 1;
  queryObj.clientInternal = 1;
  queryObj.clientExternal = 1;

  return queryObj;
}

export function formToMiniQuery(
  creditVal: number,
  secureVal: number,
  periodVal: number
) {
  const queryObj: queryObjType = {};
  // pseudo DTO
  queryObj.creditValue = creditVal;
  queryObj.secureValue = secureVal;
  queryObj.creditPeriod = periodVal * 12;

  queryObj.fixedInterestRate = 1;
  queryObj.variableInterestRate = 1;
  queryObj.periodicalInterestRate = 0;
  queryObj.eco = 0;
  queryObj.bestBankOffer = 1;
  queryObj.indexWibor = 1;
  queryObj.indexWiron = 1;
  queryObj.clientInternal = 1;
  queryObj.clientExternal = 1;

  return queryObj;
}

export function productListToStatistics(data: ProductItemType[]) {
  const stats = {
    offers: 0,
    minInstallment: 0,
    maxInstallment: 0,
  };
  data.forEach((item: ProductItemType) => {
    //console.log("odebrane22===", item);
    const installment =
      item.interest.type === "fixed_interest_rate"
        ? item.installment.equal.fixedInterestRate.installmentValue
        : item.installment.equal.monthly;

    if (installment < stats.minInstallment || stats.offers === 0) {
      stats.minInstallment = installment;
    }
    if (installment > stats.maxInstallment || stats.offers === 0) {
      stats.maxInstallment = installment;
    }
    stats.offers += 1;
  });
  // console.log("po liczeniu==", stats);
  return stats;
}
