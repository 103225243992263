import axiosInstance from "./AxiosInstance";

export async function getData(url: string, config: any) {
  const response: { data: any; status: null | number } = {
    data: null,
    status: null,
  };

  try {
    const res = await axiosInstance.get(url, config);
    const data = res.data;

    response.data = data;
    response.status = res.status;
  } catch (error) {
    response.data = null;
    response.status = null;
  }

  return response;
}

export async function postInternalData(url: string, config: any) {
  const response: { data: any; status: null | number } = {
    data: null,
    status: null,
  };

  try {
    // AXIOS - CORS bug with POST
    //const res = await axiosInstance.post(url, config);

    const res = await fetch(url, config);

    response.data = res.statusText;
    response.status = res.status;
  } catch (error) {
    response.data = null;
    response.status = null;
  }

  return response;
}

export async function postExternalData(url: string, config: any) {
  const response: { data: any; status: null | number } = {
    data: null,
    status: null,
  };

  try {
    const res = await fetch(url, config);

    response.data = res.statusText;
    response.status = res.status;
  } catch (error) {
    response.data = null;
    response.status = null;
  }

  return response;
}
