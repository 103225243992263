import ToUrlParamsMod from "../exporters/toUrlParams";
import ToHostFunctionMod from "../exporters/toHostFunction";
import InternalSFMod from "../exporters/internalSF";
import ExternalEndpointMod from "../exporters/externalEndpoint";

import type { sfConfType } from "@/types/sfConfType";

const exporterList: any = {
  toUrlParams: ToUrlParamsMod,
  toHostFunction: ToHostFunctionMod,
  internalSF: InternalSFMod,
  internalForm: ExternalEndpointMod,
};

export const ExporterFactory = {
  createExporter(sfConfig: sfConfType) {
    const type = sfConfig.sendType;
    const exporterClass = exporterList[type] ? exporterList[type] : null;

    if (exporterClass !== null) {
      return new exporterClass(sfConfig);
    }
    throw new Error("brak exportera");
  },
};
