
import { Ref, defineComponent, nextTick, onMounted, ref } from "vue";

import type { CustomWindow } from "@/types/window";
import type { sfConfType } from "@/types/sfConfType";

import { showAsCurrency } from "@/services/showAsCurrency";
import {
  formToMiniQuery,
  productListToStatistics,
} from "../services/DataToParams";
import FetchData from "../services/FetchDataService";

import Slider from "@vueform/slider";

declare let window: CustomWindow;

export default defineComponent({
  name: "MiniWidget",
  props: {
    secureValue: {
      type: Number,
      required: true,
      default: 350000,
    },
    checkPathUrl: {
      type: String,
      required: true,
      default: "https://widget-mortgage.vercel.app",
    },
    filterButton: {
      type: String,
      required: true,
      default: "",
    },
    campaignId: {
      type: String,
      required: true,
      default: "",
    },
  },
  components: {
    Slider,
  },
  setup: function (props) {
    const sfConfig: Ref<sfConfType | null> = ref(null);
    const ownContributionPercent = ref(20);
    const ownContributionValue = ref(0);
    const creditPeriod = ref(30);
    const creditValue = ref(0);
    const statOffers = ref(0);
    const statMinInstallment = ref(0);
    const statMaxInstallment = ref(0);
    const loading = ref(false);

    const ownContributionUpdate = (val: number) => {
      ownContributionValue.value = (props.secureValue * val) / 100;
      recountOffers();
    };

    const creditPeriodUpdate = (val: number) => {
      recountOffers();
    };

    const recountOffers = () => {
      loading.value = true;
      nextTick(() => {
        creditValue.value = props.secureValue - ownContributionValue.value;

        const queryString = formToMiniQuery(
          creditValue.value,
          props.secureValue,
          creditPeriod.value
        );

        const result = FetchData.getMortgageProductsMini(
          queryString,
          sfConfig.value?.readToken
        );

        result.then((resObj) => {
          if (resObj.status === 200) {
            // console.log("odebrane===", resObj);
            const stats = productListToStatistics(resObj.data);

            statOffers.value = stats.offers;
            statMinInstallment.value = stats.minInstallment;
            statMaxInstallment.value = stats.maxInstallment;

            // statOffers.value = resObj.data.totalResults;
            // statMinInstallment.value = resObj.data.minInterestRate;
            // statMaxInstallment.value = resObj.data.maxInterestRate;
            loading.value = false;
          }
        });
      });
    };

    const checkMortgageOffer = () => {
      const urlParams = `/?creditValue=${creditValue.value}&secureValue=${props.secureValue}&creditPeriod=${creditPeriod.value}&campaignId=${props.campaignId}`;
      const externalUrl = props.checkPathUrl + urlParams;
      window.location.href = externalUrl;
    };

    onMounted(() => {
      const widgetConfig = window.widgetConfig;
      if (typeof widgetConfig !== "undefined") {
        sfConfig.value = widgetConfig;
      }
      ownContributionValue.value =
        (props.secureValue * ownContributionPercent.value) / 100;
      recountOffers();
    });

    return {
      ownContributionPercent,
      ownContributionValue,
      creditPeriod,
      creditValue,
      ownContributionUpdate,
      creditPeriodUpdate,
      recountOffers,
      statOffers,
      statMinInstallment,
      statMaxInstallment,
      showAsCurrency,
      loading,
      checkMortgageOffer,
    };
  },
});
