
import {
  Ref,
  computed,
  defineComponent,
  getCurrentInstance,
  nextTick,
  onMounted,
  ref,
  toRaw,
} from "vue";

import type { CustomWindow } from "@/types/window";
import type { sfConfType } from "@/types/sfConfType";
import type { ProductItemType } from "@/types/product";
import { showAsCurrency } from "@/services/showAsCurrency";
import { formToQuery } from "../services/DataToParams";
import FetchData from "../services/FetchDataService";
import { ExporterFactory } from "../services/exporterFactory";

import ComboInputSlider from "./ui/ComboInputSlider.vue";
import InputSelect from "./ui/InputSelect.vue";
import ProductItem from "./ProductItem.vue";
import ContactForm from "./ContactForm.vue";

declare let window: CustomWindow;

export default defineComponent({
  name: "WebWidget",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    contactTitle: {
      type: String,
      required: true,
      default: "",
    },
    filterButton: {
      type: String,
      required: true,
      default: "",
    },
    resultButton: {
      type: String,
      required: true,
      default: "",
    },
    contactFormButton: {
      type: String,
      required: true,
      default: "",
    },
    campaignId: {
      type: String,
      required: true,
      default: "",
    },
  },
  components: {
    ComboInputSlider,
    InputSelect,
    ProductItem,
    ContactForm,
  },
  setup: function (props) {
    const sfConfig: Ref<sfConfType | null> = ref(null);
    const creditVal = ref(400000);
    const periodVal = ref(30);
    const creditPurposeVal = ref("1");
    const creditPurposeOptions = [
      { id: "1", title: "zakup" },
      { id: "13", title: "zakup i wykończenie / remont" },
      { id: "2", title: "wykup" },
      { id: "3", title: "remont / wykończenie" },
      { id: "4", title: "refinansowanie kredytu" },
      { id: "5", title: "refinansowanie kosztów zakupu" },
      { id: "6", title: "refinansowanie kosztów inwestycji" },
      { id: "7", title: "konsolidacja" },
      { id: "8", title: "budowa" },
    ];
    const secureVal = ref(500000);
    const ageVal = ref(30);
    const propertySortVal = ref("9");
    const propertySortOptions = [
      { id: "19", title: "brak" },
      { id: "9", title: "mieszkanie rynek wtórny" },
      { id: "8", title: "mieszkanie rynek pierwotny" },
      { id: "2", title: "dom rynek wtórny" },
      { id: "1", title: "dom rynek pierwotny" },
      { id: "17", title: "lokal użytkowy rynek wtórny" },
      { id: "14", title: "lokal użytkowy rynek pierwotny" },
      { id: "15", title: "działka i budowa" },
      { id: "18", title: "dom w budowie" },
      { id: "5", title: "działka budowlana" },
      { id: "3", title: "dom letniskowy całoroczny" },
      { id: "6", title: "działka rekreacyjna" },
      { id: "12", title: "siedlisko" },
      { id: "7", title: "działka rolna" },
    ];

    const modal = ref(false);
    const inputForceUpdate = ref(1);

    const isLoading = ref(false);
    const showResults = ref(false);
    const resultList = ref<ProductItemType[]>([]);

    const dataToSend = ref({
      campaignId: "",
      user: {
        name: "",
        city: "",
        phone: "",
        email: "",
        message: "",
      },
      filters: {
        creditValue: 0,
        creditPeriod: 0,
        creditPurpose: "",
        secureValue: 0,
        creditClientAge: 0,
        propertySort: "",
      },
      productItem: {
        id: 0,
        title: "",
        bank: "",
      },
    });

    const showContact = (item: ProductItemType) => {
      if (sfConfig.value === null) {
        console.log("brak pliku konfiguracyjnego");
        return false;
      }

      dataToSend.value.productItem = {
        id: item.id,
        title: item.title,
        bank: item.bank.name,
      };

      if (
        sfConfig.value.sendType === "internalSF" ||
        sfConfig.value.sendType === "internalForm"
      ) {
        modal.value = !modal.value;
      } else {
        const externalExport = ExporterFactory.createExporter(sfConfig.value);
        externalExport.addData(dataToSend.value);
        externalExport.send();
      }
    };

    const closeContact = () => {
      modal.value = !modal.value;
    };

    const instance = getCurrentInstance();
    const sanitize = instance?.appContext.config.globalProperties.$sanitize;

    const changedCreditVal = (value: number) => {
      creditVal.value = value;
    };
    const changedPeriodVal = (value: number) => {
      periodVal.value = value;
    };
    const changedCreditPurposeVal = (value: string) => {
      creditPurposeVal.value = value;
    };
    const changedSecureVal = (value: number) => {
      secureVal.value = value;
    };
    const changedAgeVal = (value: number) => {
      ageVal.value = value;
    };
    const changedPropertySortVal = (value: string) => {
      propertySortVal.value = value;
    };

    const mortgageCheckClick = (e: MouseEvent) => {
      if (e.target !== null) {
        (e.target as HTMLElement).blur();
      }
      mortgageCheck();
    };

    const mortgageCheck = () => {
      isLoading.value = true;
      nextTick(() => {
        const queryString = formToQuery(
          creditVal.value,
          periodVal.value,
          creditPurposeVal.value,
          secureVal.value,
          ageVal.value,
          propertySortVal.value
        );

        const result = FetchData.getMortgageProducts(
          queryString,
          sfConfig.value?.readToken
        );

        result.then((resObj) => {
          if (resObj.status === 200) {
            resultList.value = resObj.data;
            // console.log("odebrane===", resObj);

            dataToSend.value.filters = {
              creditValue: toRaw(creditVal.value),
              creditPeriod: toRaw(periodVal.value),
              creditPurpose: toRaw(creditPurposeVal.value),
              secureValue: toRaw(secureVal.value),
              creditClientAge: toRaw(ageVal.value),
              propertySort: toRaw(propertySortVal.value),
            };

            showResults.value = true;
            isLoading.value = false;
          }
        });
      });
    };

    const resultInfo = computed(() => {
      return sanitize(sfConfig.value?.resultInfo);
    });

    onMounted(() => {
      const widgetConfig = window.widgetConfig;
      if (typeof widgetConfig !== "undefined") {
        sfConfig.value = widgetConfig;
      }

      dataToSend.value.campaignId = props.campaignId;

      const params = new URLSearchParams(window.location.search);
      if (
        params.has("creditValue") &&
        params.has("secureValue") &&
        params.has("creditPeriod")
      ) {
        nextTick(() => {
          creditVal.value = Number(params.get("creditValue"));
          secureVal.value = Number(params.get("secureValue"));
          periodVal.value = Number(params.get("creditPeriod"));
          dataToSend.value.campaignId = String(params.get("campaignId"));

          inputForceUpdate.value++;
          mortgageCheck();
        });
      }
    });

    return {
      sfConfig,
      creditVal,
      periodVal,
      creditPurposeVal,
      creditPurposeOptions,
      secureVal,
      ageVal,
      propertySortVal,
      propertySortOptions,
      changedCreditVal,
      changedPeriodVal,
      changedCreditPurposeVal,
      changedSecureVal,
      changedAgeVal,
      changedPropertySortVal,
      mortgageCheckClick,
      mortgageCheck,
      resultList,
      showResults,
      isLoading,
      showContact,
      closeContact,
      modal,
      showAsCurrency,

      dataToSend,

      resultInfo,
      inputForceUpdate,
    };
  },
});
