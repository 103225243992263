import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebWidget = _resolveComponent("WebWidget")!
  const _component_MiniWidget = _resolveComponent("MiniWidget")!

  return (_ctx.mode === 'full')
    ? (_openBlock(), _createBlock(_component_WebWidget, {
        key: 0,
        title: _ctx.title,
        "contact-title": _ctx.contactTitle,
        "filter-button": _ctx.filterButton,
        "result-button": _ctx.resultButton,
        "contact-form-button": _ctx.contactFormButton,
        "campaign-id": _ctx.campaignId,
        class: "WidgetComponent"
      }, null, 8, ["title", "contact-title", "filter-button", "result-button", "contact-form-button", "campaign-id"]))
    : (_ctx.mode === 'mini')
      ? (_openBlock(), _createBlock(_component_MiniWidget, {
          key: 1,
          "secure-value": _ctx.secureValue,
          "check-path-url": _ctx.checkPathUrl,
          "filter-button": _ctx.filterButton,
          "campaign-id": _ctx.campaignId,
          class: "MiniWidgetComponent"
        }, null, 8, ["secure-value", "check-path-url", "filter-button", "campaign-id"]))
      : _createCommentVNode("", true)
}